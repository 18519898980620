@import "variables.scss";

ion-menu {
    // coloring of left menu panel
    --ion-background-color: var(--ion-menu-background);
    --ion-text-color: var(--ion-color-primary-contrast);
    --border: none;
    --min-width: 320px;
    max-width: $left-panel-width !important;
    transition: max-width 0.25s linear;

    ion-header {
        height: calc(72px + #{$global-padding});
        background: var(--ion-menu-header-overlay, transparent);

        // IE/EDGE support --> adding important...
        ion-toolbar {
            // header background in left menu panel
            --background: transparent !important;
            --min-height: calc(64px + #{$global-padding}) !important;
            height: calc(64px + #{$global-padding});

            ion-title.title-default {
                height: calc(94px + #{$global-padding}) !important;
                padding: $global-padding*1.75 !important;
                text-align: left !important;
                background-image: var(--ion-logo-menu, #{$default-logo});
                background-position: 28px 28px;
                background-repeat: no-repeat;
                background-size: auto 52px;

                // img {
                //     margin-top: 0;
                //     width: 159px;
                //     margin-bottom: 22px;
                // }
                a {
                    display: block;
                    width: 100%;
                    height: calc(94px + #{$global-padding}) !important;
                }
            }
        }

        &.not-logged {
            ion-toolbar {
                ion-title.title-default {
                    img {
                        margin-top: 0;
                        margin-bottom: 0;
                        width: 158px;
                    }
                }
            }
        }
    }

    // EDGE hotfix for menu color
    ion-content.sc-ion-content-h {
        --background: var(--ion-menu-overlay, transparent);
        background: var(--ion-menu-overlay, transparent);
    }

    ion-content {
        --background: var(--ion-menu-overlay, transparent);
        background: var(--ion-menu-overlay, transparent);

        .event-switch {
            font-weight: normal;
            margin-left: $global-padding*1.5;
            margin-bottom: $global-padding;
            color: var(--ion-color-menu, #{$menu-color});
            width: 82%;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-size: 88%;

            span {
                color: var(--ion-color-menu, #{$menu-color});
                display: block;
                float: right;
                cursor: pointer;
                --icon-color: var(--ion-color-menu, #{$menu-color});

                svg-icon {
                    margin-left: 4px;
                }

                ion-badge {
                    @include notification-icon(18px);
                    margin-right: 10px;

                    @supports (-ms-ime-align:auto) {
                        top: 7px;
                    }
                }
            }
        }

        .menu-switch {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid var(--ion-color-menu, #CCCCCC);
            background: var(--ion-menu-switch-header-overlay);
            margin: -#{$global-padding} 0 $global-padding;

            div {
                position: relative;
                flex-grow: 1;
                width: 45%;
                color: var(--ion-color-menu-tint, #{$menu-color-highlight});
                text-align: center;
                border-bottom: 2px solid transparent;
                cursor: pointer;
                line-height: 3;
                text-transform: uppercase;

                &:nth-child(2) {
                    width: 55%;
                }

                &.active {
                    color: var(--ion-color-menu-highlight);
                    border-bottom: 2px solid var(--ion-color-menu-highlight);
                }

                &.unread {
                    padding-right: 32px;
                }

                ion-badge {
                    @include notification-icon(24px);
                    top: 12px;
                    right: 12px;
                }
            }
        }

        p.welcome-text {
            margin: $global-padding*2 0;
            padding: 0 #{$global-padding*1.75};
            color: var(--ion-color-menu-tint, #{$menu-color-highlight});
            font-size: 175%;
            font-weight: 300;
            line-height: 1.3;
        }

        ion-list.list-ios {
            z-index: 999999999;
            /* optional, but it needs when you use gradient as a background color.*/
            background: transparent;
            //margin-top: -12px !important;
            margin-top: 8px !important;
            padding-bottom: 0; // keep place for ion-footer
            margin-bottom: 0;

            >hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid var(--ion-color-menu, #{$menu-color});
                margin: $global-padding $global-padding*1.5;
                padding: 0;

                &.full {
                    margin: $global-padding 0;
                }
            }

            ion-item {
                --background: transparent;
                --background-activated: transparent;
                --color: var(--ion-color-menu, #{$menu-color});
                padding: 0 0 0 $global-padding*1.5;

                >ion-avatar:not(.small-radius) {
                    width: 56px;
                    height: 56px;
                }

                >ion-avatar.small-radius {
                    --border-radius: 8px;
                }

                &.profile {
                    padding-bottom: 12px;
                }

                &.invisible {
                    font-size: 12px;

                    svg-icon {
                        margin-right: $global-margin;
                    }

                    ion-label {
                        white-space: normal;

                        span {
                            text-decoration: underline;
                            cursor: pointer;
                        }
                    }
                }
            }

            >ion-menu-toggle {
                cursor: pointer;

                >ion-item {
                    --detail-icon-opacity: 0;

                    strong {
                        color: var(--ion-color-menu-tint, #{$menu-color-highlight});
                    }

                    ion-icon {
                        // color for the icons in main menu
                        color: var(--ion-color-menu-tint, #{$menu-color-highlight});
                    }

                    img.logo {
                        margin-right: $global-padding;
                        border-radius: $global-radius;
                        object-fit: contain;
                        background-color: #FFFFFF;
                    }

                    svg-icon {
                        position: absolute;
                        margin: 2px $global-padding*0.75 0 0;

                        &.event-key {
                            svg {
                                width: 32px;
                                height: 32px;
                            }
                        }

                        &.marketplace {
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    svg-icon+ion-label {
                        padding-left: 36px !important;
                    }

                    svg-icon.event-key+ion-label.event-key {
                        padding-left: 48px !important;
                    }

                    ion-badge {
                        @include notification-icon(24px);
                        margin-right: 12px;
                        margin-top: 10px;
                    }

                    &.active,
                    &:hover {
                        ion-label.sc-ion-label-ios-h {
                            --color: var(--ion-color-menu-highlight);

                            strong {
                                color: var(--ion-color-menu-highlight);
                            }
                        }

                        svg-icon {

                            // color for the icons in main menu
                            svg {
                                fill: var(--ion-color-menu-highlight) !important;
                                stroke: var(--ion-color-menu-highlight) !important;
                            }
                        }
                    }

                    ion-button {
                        width: 100%;
                        font-size: 16px;
                        height: 38px !important;
                    }
                }

                &.activated {
                    color: var(--ion-color-primary);
                }
            }
        }
    }

    ion-footer.footer-ios {
        // position: fixed;
        // bottom: 0;
        --background: var(--ion-color-menu-footer, var(--ion-color-overlay, transparent));
        width: $left-panel-width;
        max-width: 100vw;

        ion-toolbar {
            --background: var(--ion-color-menu-footer, var(--ion-color-overlay, transparent));
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            // IE/EDGE support --> adding important...
            ion-button {
                --background: transparent !important;
                --button-background-activated: transparent !important;
                --background-hover: transparent !important;
                --color: var(--ion-color-menu, #{$menu-color}) !important;
                margin: 8px 0;

                button {
                    padding: 0 0 0 $global-padding*1.75;
                }

                &.activated,
                &.active,
                &:hover {
                    --color: var(--ion-color-menu-highlight) !important;
                    --color-activated: var(--ion-color-menu-highlight) !important;

                    svg-icon {

                        // color for the icons in main menu
                        svg {
                            fill: var(--ion-color-menu-highlight) !important;
                        }
                    }
                }

                &.logout {
                    float: right;
                    min-width: 65px;

                    button {
                        padding: 0 $global-padding*1.75 0 0;
                    }

                    svg-icon {
                        margin-left: $global-padding*0.75;
                    }
                }
            }

            &:first-child {
                --border-width: 0;
            }
        }
    }

    .offline {
        line-height: 3em;
        text-align: center;
    }

    .footer-links {
        text-align: center;

        a {
            padding: 0 $global-padding;
            color: var(--ion-color-menu, #{$menu-color});
        }
    }

    &.hidden {
        max-width: 0 !important;
        min-width: 0 !important;
        overflow-x: hidden;
    }

    .personal-links {
        margin: 0 $global-padding*1.5 -1px;
        padding: 0 !important;
        border-top: 1px solid var(--ion-color-menu, #{$menu-color});
        border-bottom: 1px solid var(--ion-color-menu, #{$menu-color});
        color: var(--ion-color-menu, #{$menu-color});

        a {
            display: block;
            width: 100%;
            color: var(--ion-color-menu, #{$menu-color});
        }

        ion-label {
            margin: 0;
        }

        ion-icon {
            float: right;
            fill: var(--ion-color-menu, #{$menu-color});
            position: relative;
            font-size: 24px;
            margin-bottom: -12px;
            margin-top: -4px;
        }

        :hover {
            color: var(--ion-color-menu-highlight);

            a {
                color: var(--ion-color-menu-highlight);
            }

            ion-icon {
                fill: var(--ion-color-menu-highlight);
                stroke: var(--ion-color-menu-highlight);
            }
        }

        &:last-of-type {
            margin-bottom: $global-padding;

            &.nats {
                @media (max-width: $breakpoint-xl) {
                    margin-bottom: -$global-padding;
                    border-bottom: none;
                }
            }
        }
    }

    @media (max-width: $breakpoint-xl) {
        max-width: 100vw !important;
        //max-height: calc(var(--app-height, 100vh));
    }
}

ion-tab-bar {
    height: 56px;
    --ion-tab-bar-background: var(--ion-color-bottom-menu, var(--ion-color-overlay, transparent));

    ion-tab-button {
        --color: var(--ion-color-menu, #{$menu-color});
        --color-selected: var(--ion-color-menu-highlight);

        ion-label {
            font-size: 10px;
            margin-bottom: 0 !important;
            min-height: 12px;
        }

        &.tab-selected {
            svg-icon {

                // color for the icons in main menu
                svg {
                    fill: var(--ion-color-menu-highlight) !important;
                    stroke: var(--ion-color-menu-highlight) !important;
                }
            }
        }

        svg-icon {
            height: 23px;
            display: flex;
            justify-content: center;
        }

        ion-badge {
            position: absolute;
            left: 53%;
            margin-top: -6px;
            border-radius: $global-radius;
            padding: 1px 6px;
        }
    }

    ion-menu-button {
        --color: var(--ion-color-menu, #{$menu-color});
        height: 27px;
        margin-top: -4px;
    }
}

.hidden {
    display: none;
}
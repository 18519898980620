@import "variables.scss";

a {
    svg-icon {
        margin-left: 4px;
        fill: var(--ion-color-secondary);

        // this is included in svg icon now...
        &.opened {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

svg-icon {
    fill: var(--icon-color);

    &.collapsible {
        display: inline-block;
        position: relative;
        width: 12px;

        svg {
            position: absolute;
            left: 0;
            margin-top: -60%;
        }

        &.opened {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

a:-webkit-any-link {
    text-decoration: none !important;
}

ion-item {
    // globaly remove bottom border from ion-item
    --border-width: 0;
    --inner-border-width: 0;
    --padding-start: 0;
    --inner-padding-start: 0;
    --inner-padding-end: 0;
    --color: #{$input-placeholder-color};
    --background: transparent;
    --highlight-background: transparent;
    padding: 0 0 $global-padding;
    font-size: 100%;

    // IE/EDGE support
    .item-native.sc-ion-item-ios {
        padding-left: 0;
        background: transparent;

        .item-inner.sc-ion-item-ios {
            color: #{$input-placeholder-color};
            background: transparent;
            padding-right: 0;
            border-bottom-width: 0;
        }
    }
}

.buttonClass {
    border-radius: $global-radius;
    font-weight: bold;
}

// IE/EDGE support --> adding important...
ion-button {
    @extend .buttonClass;
    --border-radius: #{$global-radius};
    margin: $global-padding 0 !important;
    line-height: $button-height !important;
    height: $button-height !important;
    min-width: 120px;

    // IE/EDGE support
    .button-native.sc-ion-button-ios {
        border-radius: #{$global-radius};
    }

    &.clear {
        font-weight: normal;
        min-width: 50px;
    }

    &.btn-close {
        color: var(--ion-color-secondary);
        --color-activated: var(--ion-color-secondary) !important;
        --background: var(--ion-color-dark-contrast) !important;
        --background-focused: var(--ion-color-dark-contrast) !important;
        --button-background-activated: var(--ion-color-dark-contrast) !important;
        --background-hover: var(--ion-color-dark-contrast) !important;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        opacity: 0.8;

        &:hover {
            opacity: 1;
        }
    }
}

button {
    &:focus {
        outline: none;
    }
}

ion-checkbox {
    --border-radius: var(--global-radius);
    --size: 24px;
    --border-color: var(--ion-color-light);
    --border-width: 1px;
    --border-style: solid;
    --background: #FFFFFF;
}

ion-anchor {
    &.pointer {
        cursor: pointer;
    }
}

ion-alert {
    .alert-wrapper.sc-ion-alert-ios {
        border-radius: $global-radius;
        box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
        background: #FFFFFF;

        @media (min-width: $breakpoint-md) {
            --max-width: 500px;
        }

        >.alert-head.sc-ion-alert-ios {
            background: var(--ion-background-color);
            border-bottom: 1px solid var(--ion-color-light);
            color: var(--ion-color-dark);
            padding: 0;

            h2 {
                margin: 0;
                text-align: left;
                font-size: 150%;
                font-weight: normal;
                line-height: 1;
                padding: $global-padding $global-padding*1.5;
            }
        }

        >.alert-message.sc-ion-alert-ios {
            font-size: 125%;
            padding: $global-padding*1.5;
            text-align: left;

            @media (max-width: $breakpoint-md) {
                max-height: 80% !important;
            }

            >.highlight {
                color: var(--ion-color-danger);
            }
        }

        >.alert-button-group.sc-ion-alert-ios {
            display: flex;
            justify-content: flex-end;
            padding: 0 $global-padding*1.5 $global-padding*1.5;

            >.alert-button.sc-ion-alert-ios {
                @extend .buttonClass;
                margin: 0;
                background: var(--ion-color-primary);
                color: var(--ion-color-primary-contrast);

                &.secondary {
                    color: var(--ion-color-secondary);
                    background: var(--ion-color-secondary-contrast);
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
                    margin-right: $global-padding;
                }
            }
        }
    }

    &.selectable {
        -moz-user-select: text !important;
        -khtml-user-select: text !important;
        -webkit-user-select: text !important;
        -ms-user-select: text !important;
        user-select: text !important;
    }

    &.confirm {
        --max-width: 500px;

        .alert-message.sc-ion-alert-ios {
            max-height: calc(var(--app-height) - 164px);
        }

        .alert-wrapper.sc-ion-alert-ios {
            min-width: 400px;

            >.alert-button-group.sc-ion-alert-ios {
                justify-content: space-between;

                >.alert-button.sc-ion-alert-ios {
                    margin: 0;
                    min-width: calc(50% - 8px);
                    width: calc(50% - 8px);
                    max-width: 48%;
                    flex-grow: 0;
                }
            }

            .qrcode {
                width: 100%;
                text-align: center;
                display: block;
            }

            @media (max-width: $breakpoint-sm) {
                min-width: 98vw;
            }
        }

        @media (max-width: $breakpoint-sm) {
            --max-width: 98vw;
        }
    }

    .alert-input-group.sc-ion-alert-ios {
        padding: 0 $global-padding*1.5 $global-padding $global-padding*1.5 !important;

        .alert-input.sc-ion-alert-ios {
            line-height: 2;
            padding-left: 12px;
        }
    }
}

ion-popover {
    --width: 312px;

    &::part(arrow) {
        display: none;
    }

    // &::part(content) {
    --box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2);
    border-radius: $global-radius;

    ion-item {
        padding: 0;
        --padding-start: #{$global-padding};
        --color: var(--ion-color-dark);
        --background: #FFF;
        --padding-bottom: 0;

        &.item-radio-checked {
            background: var(--ion-background-color);
        }

        // }
    }

    &.limit {
        --width: 104px;
    }

    &.appointment-participant-list {
        --width: 388px;
        --max-width: 100%;
    }

    &.edit-note {
        --width: 312px;

        &::part(arrow) {
            display: block;
        }

        @media (max-width: $breakpoint-sm) {
            --width: 97vw;

            &::part(arrow) {
                display: none;
            }

            &::part(content) {
                top: 60px !important;
                left: 1.5vw !important;

                ion-button {
                    width: 130px;
                }
            }
        }
    }

    &.not-overflow {
        @supports (-ms-ime-align:auto) {
            &::part(content) {
                overflow: hidden;
            }
        }
    }

    @media (max-width: $breakpoint-sm) {
        --width: calc(100vw - 8px);
    }
}

.modal-header {
    font-size: 125%;
    padding: $global-padding $global-padding*1.5;
    background: var(--ion-background-color);
    border-bottom: 1px solid #{$input-border-color};

    a {
        cursor: pointer;
    }
}

ion-header {
    ion-toolbar {
        &:first-of-type {
            padding-top: 0 !important;
        }
    }
}

ion-modal {
    --border-radius: #{$global-radius};

    ion-header {
        padding-right: 0;
    }

    ion-toolbar {
        height: 56px;
        border-bottom: 1px solid var(--ion-color-light);

        &:first-of-type {
            padding-top: 0 !important;
        }

        ion-title {
            height: 56px;
        }

        ion-button {
            margin: 0 !important;
            min-width: 0;
            height: 48px;
        }

        .back-link {
            display: block;
        }
    }

    .content-centered-column {
        min-height: 88%;
    }

    &.file-modal {
        @media (min-width: $breakpoint-xl) {
            --min-width: 75vw !important;
            //--min-height: calc(var(--app-height, 100vh) - 64px) !important;
        }

        @media (max-width: $breakpoint-sm) {
            --min-height: calc(var(--app-height, 100vh)) !important;
        }
    }

    &.datetime-picker {
        --width: fit-content;
        --min-width: 250px;
        --height: fit-content;
    }

    &.attendee-modal,
    &.community-filter-modal,
    &.file-modal,
    &.keyword-select-modal,
    &.marketplace-detail,
    &.marketplace-details-modal,
    &.message-file-form-modal,
    &.message-form,
    &.newsfeed-detail,
    &.newsfeed-form,
    &.video-modal,
    &.newsfeed-report-form {
        // --height: #{$breakpoint-height-modal};
        --height: auto;
        --min-width: var(--width);
        --min-height: #{$breakpoint-height-modal};

        &::part(content) {
            position: relative;
            display: block;
            contain: content;
            background: var(--ion-background-contrast-color);

            .ion-page {
                display: initial;
                position: inherit;

                .attendee-detail {
                    min-height: 724px;

                    @media (max-width: $breakpoint-sm) {
                        min-height: 0;
                    }
                }
            }
        }

        @media (min-width: #{$breakpoint-xl + 1}) {
            --width: 960px;
        }

        @media (max-width: $breakpoint-xl) {
            --min-width: 100vw;
            --width: 100vw;
        }

        @media (max-height: $breakpoint-height-modal) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--height);
        }

        @media (max-width: $breakpoint-sm) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--height);
        }
    }

    &.video-modal {
        @media (min-width: $breakpoint-sm) {
            --min-width: var(--app-width, 100vw);
            --min-height: var(--app-height, 100vh);
            --height: fit-content;
        }

        &.modal-ios {
            @media (max-width: $breakpoint-sm) {
                // make it bigger to cover all bacground with black
                --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px) + 4px);
                --min-height: var(--height);
            }
        }
    }

    &.community-filter-modal,
    &.keyword-select-modal {
        @media (min-width: $breakpoint-sm) {
            --min-height: 0;
        }
    }

    &.keyword-select-modal.small {
        @media (min-width: #{$breakpoint-xl + 1}) {
            --width: 720px;
        }
    }

    &.event-modal,
    &.wizard-modal {
        --width: 960px;
        --min-width: var(--width);

        &::part(content) {
            background: var(--ion-background-contrast-color);

            .ion-page {
                display: initial;
                position: inherit;
                justify-content: flex-start;
            }
        }

        .ion-page {
            display: initial;
            position: inherit;
            justify-content: flex-start;
        }

        @media (max-width: $breakpoint-lg) {
            --width: 100vw;
            --min-width: 100vw;
        }

        @media (max-width: $breakpoint-sm) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--height);
        }
    }

    &.wizard-modal {
        --width: 1124px;
        --min-height: calc(var(--app-height, 100vh) - 60px);

        @media (max-width: $breakpoint-lg) {
            --width: 100vw;
        }

        @media (min-height: 1080px) {
            --min-height: 904px;
        }
    }

    &.file-modal,
    &.message-file-form-modal {
        --min-height: 320px;

        @media (max-height: $breakpoint-height-modal) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--height);
        }
    }

    &.file-modal {
        --height: 80vh;
    }

    &.marketplace-details-modal {
        @media (min-width: #{$breakpoint-xl + 1}) {
            // --width: 1200px;
            // NOTE[jg] this was causing some mess with showing mobile view after ionic update.. disabled
            // --height: 380px;
        }
    }

    &.qr-scan {
        --background: transparent;

        &::part(content) {
            @media (max-height: $breakpoint-height-modal) and (min-width: $breakpoint-sm) {
                height: 100vh !important;
            }
        }
    }

    &.form,
    &.newsfeed-report-form {
        --width: 600px;
        --height: auto;
        --min-height: 420px;

        @media (max-width: $breakpoint-sm) {
            &::part(content) {
                overflow-y: auto;
                -webkit-overflow-scrolling: touch;
            }

            --width: 100vw;
            --height: var(--app-height, 100vh);

            .ion-page {
                min-height: 432px;
            }
        }
    }

    &.newsfeed-form {
        --min-height: 600px;

        @media (max-height: 600px) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--height);
        }

        @media (max-height: 600px) and (min-width: $breakpoint-sm) {
            &::part(content) {
                overflow: auto;

                .ion-page {
                    display: initial;
                    position: inherit;
                }
            }
        }

        @media (max-width: $breakpoint-sm) {
            --min-height: var(--height);
        }
    }

    &.chat-group-form {
        --min-height: 740px;
        --height: 740px;
        --border-radius: #{$global-radius} !important;
        --overflow: visible;

        @media (max-height: 740px) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --min-height: var(--app-height, 100vh);
            --height: var(--app-height, 100vh);
        }

        @media (max-width: $breakpoint-sm) {
            --height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px));
            --height: var(--app-height);
        }
    }

    &.group-select-modal {
        --width: 320px;
        --width: 420px;
        --height: 400px;
        --min-height: 0;

        @media (max-width: $breakpoint-sm) {
            --max-height: var(--app-height);
            --height: var(--app-height);
        }
    }

    &.attendee-modal {
        --overflow: visible;

        &::part(content) {
            overflow: visible;
        }
    }

    &::part(content) {
        border: 1px solid var(--ion-color-light);
    }

    @media (max-width: $breakpoint-xl) {
        --border-radius: 0;
    }

    @media (max-width: $breakpoint-sm) {
        --max-height: var(--app-height);
        --height: var(--app-height);
    }

    // fix low height screen with keypad
    @media (max-height: $breakpoint-height-modal) and (min-width: $breakpoint-sm) {
        &::part(content) {
            height: 70vh;
            overflow: auto;

            .ion-page {
                height: 100vh;
            }

            app-new-question.ion-page {
                height: 420px;
            }
        }
    }
}

ion-toast {
    --border-radius: #{$global-radius};
    --border-width: 1px;
    --border-style: solid;
    --border-color: var(--ion-background-color);
    font-size: 100%;
    margin: $global-padding $global-padding $global-padding auto !important;
    padding-left: $global-padding;
    position: absolute;
    left: calc(50% + 236px);
    width: 480px;
    max-height: calc(var(--app-height, 100vh) - 64px);
    max-width: 100vw;
    word-break: break-word;

    &.with-button {
        --button-color: var(--ion-color-medium);

        &::part(button) {
            color: var(--ion-color-medium);
            background: var(--ion-color-primary-contrast);
            margin: 0 calc($global-padding / 2) 0 calc($global-padding / 2);
            border-radius: $global-radius;
        }

        @media (max-width: $breakpoint-sm) {
            &::part(button) {
                margin: 0 5vw;
            }
        }
    }

    &.with-buttons {
        --button-color: var(--ion-color-medium);

        &::part(button) {
            color: var(--ion-color-medium);
            background: var(--ion-color-primary-contrast);
            margin: 0 calc($global-padding / 2) 0 calc($global-padding / 2);
            border-radius: $global-radius;
        }

        @media (max-width: $breakpoint-sm) {
            &::part(button) {
                margin: 0 5vw;
            }
        }

        @media (max-width: $breakpoint-sm-real) {
            &::part(container) {
                flex-direction: column;
                padding-bottom: $global-padding;
            }
        }
    }

    @media (max-width: 1432px) {
        left: auto;
        right: 0;
    }

    @media (max-width: $breakpoint-sm) {
        left: auto;
        right: 0;
        margin: $global-padding 0 0 0 !important;
    }

    @supports (-ms-ime-align:auto) {
        .toast-wrapper.sc-ion-toast-ios {
            width: $content-width;
            max-width: calc(100vw - 352px);
            margin: 0 auto !important;
            position: absolute;
            left: 0 !important;
            right: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
            background-color: transparent !important;

            .toast-container.sc-ion-toast-ios {
                width: 450px;
                float: right;
                background-color: var(--ion-color-primary);
                border-radius: $global-radius;
            }
        }

        &.sc-ion-toast-ios-h {
            width: calc(-320px + 100%);
            position: absolute;
            right: -15px;
        }
    }
}

.toast-wrapper {
    right: 8vw !important;
    left: auto !important;
    margin: $global-padding $global-padding $global-padding auto !important;
}

dropzone {
    color: var(--ion-color-secondary) !important;

    >.dropzone.dz-wrapper {
        color: var(--ion-color-secondary) !important;
        overflow: hidden !important;
        margin-top: 6px;

        .dz-message {
            color: var(--ion-color-secondary) !important;
            min-width: calc(100% - 50px) !important;
            max-width: calc(100% - 50px) !important;
            border: none !important;
            text-align: left !important;
            padding-left: 20px !important;
            margin: 0 !important;
            background: transparent url("/assets/icons/ico-photo.svg") no-repeat left 11px !important;
            //display: none !important;
            overflow: hidden !important;

            .dz-text {
                width: calc(100% - 50px) !important;
                text-align: left !important;

                span {
                    color: #{$input-placeholder-color} !important;
                }

                @media (max-width: $breakpoint-sm) {
                    width: 100% !important;
                }
            }

            @media (max-width: $breakpoint-sm) {
                padding-left: 14px !important;
            }
        }

        .dz-image {
            display: none !important;
        }

        .dz-error {
            min-height: 160px;

            .dz-details,
            .dz-error-message {
                opacity: 1 !important;
                width: 100% !important;
            }
        }
    }
}

.dropdown {
    margin: $global-padding 0 0;

    .dropdown-header {
        cursor: pointer;
        height: 24px;
        padding-top: 16px;

        hr {
            margin: 0 !important;
            padding: 0 !important;
        }

        h2 {
            text-transform: capitalize;
            position: absolute;
            margin-top: -13px !important;
            padding-right: $global-padding;
            background: var(--ion-background-color);
            color: var(--ion-color-dark);
            font-weight: normal;
            font-size: 125% !important;
            margin-bottom: 0;
        }

        svg-icon {
            position: absolute;
            right: $global-padding;
            margin-top: -12px;
            background: var(--ion-color-secondary-contrast);
            width: 20px;
            height: 20px;
            border-radius: $global-radius;
            text-align: center;
            border: 1px solid #{lighten($light-color, 10%)};
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 4px;

            svg {
                fill: var(--ion-color-secondary);
            }
        }
    }

    .dropdown-content {
        max-height: 0;
        margin-top: $global-padding*0.5;
        transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
        overflow: hidden;
    }

    &.opened {
        .dropdown-header {
            h2 {
                font-weight: bold;
            }

            svg-icon {
                background: var(--ion-color-secondary);
                border: none;
                padding-left: 5px;

                svg {
                    transform: rotate(180deg);
                    fill: var(--ion-color-secondary-contrast);
                }
            }
        }

        .dropdown-content {
            max-height: 600px;
            transition: max-height 1s ease-in-out;
        }
    }

    &.right-arrow {
        .dropdown-header {
            svg-icon {
                background: var(--ion-color-secondary);
                border: none;
                padding-left: 5px;

                svg {
                    transform: rotate(270deg);
                    fill: var(--ion-color-secondary-contrast);
                }
            }
        }
    }

    &.detail {
        .dropdown-header {
            h2 {
                background: #FFFFFF;
            }

            svg-icon {
                right: 0;
                position: relative;
                float: right;
            }
        }
    }
}

ion-searchbar.sc-ion-searchbar-ios-h {
    --background: var(--ion-background-contrast-color);
    --color: var(--ion-color-medium);
    --icon-color: var(--ion-color-medium);
    --placeholder-color: var(--ion-color-medium);
    max-width: 280px;
    box-shadow: none;

    >.sc-ion-searchbar-ios {
        border-radius: 20px;
        overflow: hidden;
        box-shadow: none;
        border: 1px solid #E7E7E7;
    }
}

ion-searchbar.sc-ion-searchbar-ios-h.without-border {
    >.sc-ion-searchbar-ios {
        border: none;

        @media (max-width: $breakpoint-sm) {
            border: 1px solid #E7E7E7;
        }
    }
}

.action-sheet-group.sc-ion-action-sheet-ios {
    @media (max-width: $breakpoint-sm) {
        overflow-y: scroll;
    }
}

.action-sheet-button {
    --color: var(--ion-color-medium);

    &.sc-ion-action-sheet-ios {
        height: 52px;

        @media (max-width: 320px) {
            height: 45px;
        }
    }

    &.action-sheet-selected {
        --color: var(--ion-color-primary);
    }
}

ion-refresher {
    z-index: 1;
}

svg-icon {
    &.avatar-fill-0 {
        use {
            fill: var(--ion-color-light);
        }
    }

    &.avatar-fill-1 {
        use {
            fill: var(--ion-color-secondary);
        }
    }

    &.avatar-fill-2 {
        use {
            fill: var(--ion-color-primary);
        }
    }
}

ion-label .event-name {
    white-space: normal;
}

.p-datepicker {
    min-width: 200px;
}
@mixin keyword-list () {
    ion-chip {
        --background: transparent;
        --color: #{darken($light-color, 15%)};
        --padding-bottom: 0;
        --padding-top: 0;
        --padding-start: 0;
        --padding-end: 0;
        border-radius: 4px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        font-weight: normal;
        font-size: 88%;
        border: 1px solid #{lighten($light-color, 10%)};
        max-width: 98%;

        ion-label {
            max-width: 100%;
            //white-space: nowrap;
            // overflow: hidden;
            //text-overflow: ellipsis;
            line-height: 1;
            text-align: left;
            word-break: break-word;
        }

        ion-spinner {
            --color: var(--ion-color-light-contrast);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: calc(100% + 2px);
            background: var(--ion-color-light);
            //opacity: 0.6;
            //margin-top: 11px;
        }

        img {
            border-radius: 50%;
            box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);
            height: 24px;
            width: 24px;
            max-height: 28px !important;
            margin-right: 8px;
            margin-left: -6px;
        }

        &.suggest {
            background: transparent;
            border: none;
            --color: var(--ion-color-dark);
            font-weight: bold;
        }

        &.enabled {
            --background: var(--ion-color-secondary);
            --color: var(--ion-color-secondary-contrast);
            border: 1px solid var(--ion-color-secondary);
            text-shadow: 1px 0 0 currentColor;

            ion-spinner {
                --color: var(--ion-color-secondary-contrast);
                background: var(--ion-color-secondary);
            }
        }

        &.match {
            --background: var(--ion-color-primary);
            --color: var(--ion-color-primary-contrast);
            border: 1px solid var(--ion-color-primary);
            font-weight: bold;
        }

        &.suggestion {
            border: none;
            --background: var(--ion-color-light);
            --color: var(--ion-color-light-contrast);
            font-weight: bold;
        }

        &.matching-list {
            img {
                box-shadow: none;
                height: 28px;
                width: 28px;

                &.matched {
                    margin-left: 6px;
                }

                &.my-match {
                    position: absolute;
                    margin-left: -10px;
                }
            }

            &.enabled {
                img {
                    border: 2px solid var(--ion-color-secondary);
                }
            }

            &.match {
                img {
                    border: 2px solid var(--ion-color-primary);
                }
            }
        }
    }

    .keyword-selected {
        display: inline-block;
        margin-right: 8px;
        font-size: 88%;
        color: var(--ion-color-dark);
    }
}
@mixin notification-icon ($size) {
    display: block;
    position: absolute;
    padding: 0;
    top: 0;
    right: 0;
    width: $size;
    height: $size;
    line-height: $size;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    font-size: 75%;
    font-weight: bold;
    border-radius: $size*0.2;
}
@mixin editable-details () {
    .details {
        hr {
            margin: $global-padding 0;

            &.separator {
                margin: $global-padding*1.5 0;

                &.enabled {
                    border-top: 1px solid var(--ion-color-light);
                }
            }
        }

        .set-line {
            background-color: var(--ion-background-color);
            margin-top: -48px;
            padding-right: $global-padding;
            width: 225px;

            ion-checkbox {
                --size: 16px;
            }
        }

        h2 {
            margin: $global-padding*1.25 0 $global-padding;

            &.margin-top {
                margin-top: $global-padding*2.5;
            }
        }

        > ion-row > ion-col > ion-row {
            margin: 0 -5px;
        }

        ion-row {
            align-items: center;
            justify-content: space-between;

            ion-col {
                &.from,
                &.to {
                    flex-grow: 0;

                    ion-item {
                        width: 140px;
                        @media (max-width: $breakpoint-sm) {
                            width: calc(50vw - #{$global-padding*1.8});
                        }
                    }
                }

                &.image {
                    flex-grow: 0;
                    padding-bottom: $global-padding;
                    margin-right: $global-margin;

                    ion-item {
                        width: 200px;
                        @media (max-width: $breakpoint-md) {
                            width: 156px;
                        }
                        @media (max-width: $breakpoint-sm) {
                            width: 300px;
                            margin-top: $global-padding;
                        }
                    }

                    .remove {
                        display: none;
                        cursor: pointer;
                        position: absolute;
                        z-index: 999;
                        top: 10px;
                        right: 10px;
                        width: 22px;
                        height: 22px;
                        border-radius: 50%;
                        background: var(--ion-color-danger) url("/assets/icons/ico-close.svg") no-repeat center center;
                        background-size: 48% 50%;
                    }
                    @media (hover: none), (pointer: coarse) {
                        .remove {
                            display: block;
                        }
                    }

                    &:hover {
                        .remove {
                            display: block;
                        }
                    }
                }

                &.actions {
                    flex: 0 0 calc(calc(1 / var(--ion-grid-columns, 10)) * 100%);
                    width: calc(calc(1 / var(--ion-grid-columns, 10)) * 100%);
                    max-width: calc(calc(1 / var(--ion-grid-columns, 10)) * 100%);
                    display: flex;
                    justify-content: flex-end;

                    ion-icon {
                        margin-left: 4px;
                    }
                }

                &.col-btn-add {
                    @media (min-width: $breakpoint-sm) {
                        position: relative;
                        top: -36px;
                    }
                }

                ion-item {
                    padding: 0 0 $global-padding*0.5;

                    ion-label {
                        margin-top: $global-padding*0.5;
                        margin-bottom: $global-padding*0.5;
                        @media (min-width: $breakpoint-sm) {
                            margin-bottom: 4px;
                        }
                    }

                    ion-thumbnail {
                        background: $input-border-color;
                        width: 100%;
                        max-width: 200px;
                        height: 126px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            max-width: 200px;
                            min-width: 200px;
                        }

                        a {
                            svg {
                                width: 48px;
                                margin: 0 80px;
                                fill: var(--ion-color-medium);
                            }
                        }

                        dropzone {
                            height: 100%;
                            width: 200px;

                            ::ng-deep .dropzone.dz-wrapper.dz-single {
                                height: 100%;
                                background: transparent url("/assets/icons/profile/pdf.svg") no-repeat;
                                background-size: 32px auto !important;
                                background-position: 52% 64% !important;

                                .dz-message {
                                    height: 100%;
                                    width: 100% !important;
                                    max-width: 100% !important;
                                    padding-left: 0 !important;
                                    background: transparent url("/assets/icons/profile/image.svg") no-repeat !important;
                                    background-size: 32px auto !important;
                                    background-position: 42% 42% !important;

                                    .dz-text {
                                        display: none;
                                    }
                                }
                            }
                        }

                        .image-actions {
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: row;

                            button {
                                width: 100%;
                                height: 100%;
                                margin-bottom: 2px;
                                border-right: 1px solid #{$light-border-color};

                                ion-icon {
                                    font-size: 250%;
                                    margin-top: 6px;
                                }
                            }

                            dropzone {
                                min-width: 33%;

                                ::ng-deep .dropzone.dz-wrapper.dz-single {
                                    background-position: 50% 50% !important;

                                    .dz-message {
                                        background: none !important;
                                    }
                                }
                            }
                        }

                        .image-upload {
                            height: 100%;
                            width: 100%;
                            display: flex;
                            flex-direction: column;

                            > div {
                                width: 100%;
                                height: 50%;
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                            }

                            progress {
                                max-width: 90%;
                            }
                        }
                        @media (max-width: $breakpoint-sm) {
                            margin: 0;
                            width: 100%;
                            max-width: 300px;
                            height: 198px;
                        }
                    }
                    @media (max-width: $breakpoint-sm) {
                        padding: 0 0 $global-padding;
                        width: calc(100vw - #{$global-padding*2});
                    }
                }

                .extend-paragraph {
                    width: 100%;
                    margin: $global-padding 0 -4px;

                    ion-col {
                        display: flex;

                        ion-icon {
                            margin-right: 8px;
                        }

                        &.right {
                            justify-content: flex-end;

                            ion-icon {
                                margin-right: 0;
                                margin-left: 8px;
                            }
                        }
                    }
                }

                .error-message,
                .hint {
                    margin: -4px 8px 0 0;
                    @media (max-width: $breakpoint-sm) {
                        margin: -16px 8px 0 0;
                    }
                }

                .hint {
                    color: #999999;
                    font-size: 14px;
                }

                .image .error-message {
                    right: 0;
                    overflow: visible;
                    text-overflow: clip;
                    white-space: normal;
                }

                ion-icon {
                    font-size: 165%;

                    &.close {
                        font-size: 225%;
                        margin-top: -4px;
                    }
                }
                @media (max-width: $breakpoint-sm) {
                    &.to {
                        padding: 0;
                        margin-right: -6px;
                    }
                }
            }
        }

        > .actions {
            position: absolute;
            z-index: 99;
            right: $global-padding;
            margin-top: 0;

            ion-icon {
                font-size: 165%;
                margin-left: 4px;
            }
        }

        > ion-row > ion-col > h3 {
            font-size: 100%;
            margin: $global-padding*0.25 0 0;
            color: var(--ion-color-medium);
            @media (max-width: $breakpoint-sm) {
                max-width: 55vw;
            }
        }

        .detail-type-header {
            margin: $global-padding*1.5 0;
            cursor: pointer;
            display: flex;
            align-items: center;

            h3 {
                margin: 0;
                color: var(--ion-color-light);
                min-width: 180px;
                font-size: 100%;
                border-bottom: 1px solid var(--ion-color-light);
            }

            ion-icon {
                margin-left: 16px;
                font-size: 165%;
            }
        }

        .detail-types {
            margin: 0 -5px;
            justify-content: flex-start;

            .detail-type {
                cursor: pointer;
                max-width: 250px;
                min-height: 90px;
                padding: $global-padding;
                background: var(--ion-background-contrast-color);
                color: var(--ion-color-medium);
                border: 1px solid var(--ion-color-light);
                border-radius: $global-radius;

                svg-icon {
                    margin: 8px 16px 22px 0;
                    fill: var(--ion-color-light);
                    height: 40px;
                    width: 40px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    float: left;

                    svg {
                        height: 40px;
                        width: 40px;
                        @media (max-width: $breakpoint-sm) {
                            height: 32px;
                            width: 32px;
                        }
                    }

                    &.image {
                        margin: 0 28px 33px 0;
                        width: 32px;
                        height: 32px;
                        @media (max-width: $breakpoint-sm) {
                            margin: 0 14px 36px -6px;
                        }
                    }

                    &.pdf {
                        position: absolute;
                        width: 32px;
                        height: 32px;
                        margin: 22px 16px 0;
                        @media (max-width: $breakpoint-sm) {
                            margin: 43px -6px;
                        }
                    }
                    @media (max-width: $breakpoint-md) {
                        margin: 8px 16px 40px 0;
                    }
                    @media (max-width: $breakpoint-sm) {
                        margin: 5px 0 40px -8px;
                    }
                }

                h3 {
                    margin: 0;
                    font-size: 100%;
                    @media (max-width: $breakpoint-sm) {
                        font-size: 80%;
                    }
                }

                p {
                    margin: 8px 0 0;
                    font-size: 75%;
                }
                @media (max-width: $breakpoint-md) {
                    min-height: 116px;
                }
                @media (max-width: $breakpoint-sm) {
                    width: calc(50vw - #{$global-padding});
                    max-width: calc(50vw - #{$global-padding*1.25});
                    min-height: 110px;
                    margin-bottom: 4px;
                }
                @media (max-width: 320px) {
                    min-height: 112px;
                }
            }
            @media (max-width: $breakpoint-sm) {
                margin: 0;
            }
        }
    }
}

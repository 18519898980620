// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

// basic colors of congreet
$primary-color-congreet: #B9D137;
$secondary-color-congreet: #58B1D7; // default opacity for avatar preview
$primary-light: #359cce;

// import theme variables
@import "theme-variables.scss";
@import "mixin.scss"; // breakpoints
$content-width: 1104px;
$breakpoint-ms: 320px;
$breakpoint-sm-real: 576px;
$breakpoint-sm: 736px;
$breakpoint-md: 768px;
$breakpoint-lg-ionic: 992px;
$breakpoint-lg: 1024px;
$breakpoint-xl: 1365px;
$breakpoint-height-modal: 724px;
$light-border-color: #e6e6e6; //#dedede;
$avatar-opacity: 0.2;
$messages-padding: $global-padding*1.25;

$header-height: 65px;
$header-height-sm: 56px;

$video-height: 400px;

$custom-black: #000012;
$custom-white: #E5E4DA;

$icon-default-size: 20px;

$details-width: 375px;

$sidebar-width: 320px;

/** Ionic CSS Variables **/
:root {
    /** primary **/
    --ion-color-primary: #{$primary-color};
    --ion-color-primary-rgb: #{$primary-color-rgb};
    --ion-color-primary-contrast: #{$primary-color-contrast};
    --ion-color-primary-contrast-rgb: #{$primary-color-contrast-rgb};
    --ion-color-primary-shade: #{darken($primary-color, 10%)};
    --ion-color-primary-tint: #{lighten($primary-color, 10%)};
    /** secondary **/
    --ion-color-secondary: #{$secondary-color};
    --ion-color-secondary-rgb: #{$secondary-color-rgb};
    --ion-color-secondary-contrast: #{$secondary-color-contrast};
    --ion-color-secondary-contrast-rgb: #{$secondary-color-contrast-rgb};
    --ion-color-secondary-shade: #{darken($secondary-color, 10%)};
    --ion-color-secondary-tint: #{lighten($secondary-color, 10%)};
    /** menu color **/
    --ion-color-menu: #{$menu-color};
    --ion-color-menu-highlight: #{$color-memu-highlight};
    --ion-color-menu-tint: #{lighten($menu-color, 25%)};
    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;
    /** success **/
    --ion-color-success: #10dc60;
    --ion-color-success-rgb: 16, 220, 96;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #0ec254;
    --ion-color-success-tint: #28e070;
    /** warning **/
    --ion-color-warning: #{$warning-color};
    --ion-color-warning-rgb: #{$warning-color-rgb};
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;
    /** danger **/
    --ion-color-danger: #{$danger-color};
    --ion-color-danger-rgb: #{$danger-color-rgb};
    --ion-color-danger-contrast: #{$danger-color-contrast};
    --ion-color-danger-contrast-rgb: #{$danger-color-contrast-rgb};
    --ion-color-danger-shade: #{darken($danger-color, 10%)};
    --ion-color-danger-tint: #{lighten($danger-color, 10%)};
    /** dark **/
    --ion-color-dark: #{$dark-color};
    --ion-color-dark-rgb: #{$dark-color-rgb};
    --ion-color-dark-contrast: #{$dark-color-contrast};
    --ion-color-dark-contrast-rgb: #{$dark-color-contrast-rgb};
    --ion-color-dark-shade: #{darken($dark-color, 10%)};
    --ion-color-dark-tint: #{lighten($dark-color, 10%)};
    /** medium **/
    --ion-color-medium: #{$medium-color};
    --ion-color-medium-rgb: #{$medium-color-rgb};
    --ion-color-medium-contrast: #{$medium-color-contrast};
    --ion-color-medium-contrast-rgb: #{$medium-color-contrast-rgb};
    --ion-color-medium-shade: #{darken($medium-color, 10%)};
    --ion-color-medium-tint: #{lighten($medium-color, 10%)};
    /** light **/
    --ion-color-light: #{$light-color};
    --ion-color-light-rgb: #{$light-color-rgb};
    --ion-color-light-contrast: #{$light-color-contrast};
    --ion-color-light-contrast-rgb: #{$light-color-contrast-rgb};
    --ion-color-light-shade: #{darken($light-color, 10%)};
    --ion-color-light-tint: #{lighten($light-color, 10%)};
    /** background **/
    --ion-background-color: #{$bg-content};
    --ion-background-contrast-color: #{$bg-content-contrast};
    /** breakpoints **/
    --ion-grid-breakpoints-sm: #{$breakpoint-sm};
    --ion-grid-breakpoints-md: #{$breakpoint-md};
    --ion-grid-breakpoints-lg: #{$breakpoint-lg};
    --ion-grid-breakpoints-xl: #{$breakpoint-xl};
    /** borders **/
    --inner-border-width: 0;
    --border-width: 0;
    --global-radius: #{$global-radius};
    /** fonts **/
    --ion-default-font: #{$default-font};
    --ion-font-family: #{$default-font};
}
/** override ios setting **/
html.ios {
    /** fonts **/
    --ion-default-font: #{$default-font};
    --ion-font-family: #{$default-font};

    &.plt-cordova {
        // ion-header {
        //     padding-top: var(--ion-safe-area-top,0);
        //
        //     ion-toolbar:first-child {
        //         padding-top: 0;
        //     }
        // }
    }
}
// congreet paths
$assets-path: '/assets/'; // congreet layout setting
$default-logo: 'url(#{$assets-path}#{$default-logo-file})';
// full web path to avoid flickering on mobile devices (there is no local header for caching)
$bg-menu: $bg-menu-color url('../assets/images/#{$bg-menu-image}') no-repeat left top !important;
$bg-dashboard: $bg-dashboard-color url('../assets/images/#{$bg-dashboard-image}') no-repeat left top;


// NOTE[jg] - not used ?? fix...
:root {
    --ion-menu-background: #{$bg-menu};
    --ion-header-background: #{$bg-dashboard};
    --ion-color-overlay: #{$bg-overlay} !important;
    --ion-menu-header-overlay: rgba(#{$bg-overlay-rgb}, 0.85) !important;
    --ion-menu-switch-header-overlay: #{transparentize(darken($bg-overlay, 15%), 0.3)} !important;
    --ion-menu-overlay: var(--theme-menu-opacity, linear-gradient(0deg, rgba(#{$bg-overlay-rgb},1) 0%, rgba(#{$bg-overlay-rgb},0.4) 20%, rgba(#{$bg-overlay-rgb},0.4) 85%, rgba(#{$bg-overlay-rgb},0.85) 100%)) !important;
    --ion-header-overlay: rgba(#{$bg-overlay-rgb}, 0.85) !important;
    --ion-color-menu-highlight: #{$color-memu-highlight};
    --translation-panel-width: 320px; //calc(var(--app-width, 100vw) - 375px);
    --app-width: 100vw;
}
// other colors
$linkedin-color: #137BB6; // left panel setting,
// !default --> will be aplied only if we don't set this variable in customer variables
$left-panel-width: 320px !default; // svg fill colors

.fill-primary {
    fill: var(--ion-color-primary);

    svg {
        fill: var(--ion-color-primary);
    }
}

.fill-secondary {
    fill: var(--ion-color-secondary);

    svg {
        fill: var(--ion-color-secondary);
    }
}

.fill-dark {
    fill: var(--ion-color-dark);

    svg {
        fill: var(--ion-color-dark);
    }
}

.fill-medium {
    fill: var(--ion-color-medium);

    svg {
        fill: var(--ion-color-medium);
    }
}

.fill-light {
    fill: var(--ion-color-light);

    svg {
        fill: var(--ion-color-light);
    }
}

.fill-menu-contrast {
    fill: var(--ion-color-menu, #{$menu-color});
    stroke: var(--ion-color-menu, #{$menu-color});


}

.fill-white {
    fill: #FFFFFF;

    svg {
        fill: #FFFFFF;
    }
}
@import "froala.scss";
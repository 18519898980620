@import "variables.scss";

@media (max-width: $breakpoint-xl) {
    .ion-hide-xl-down {
        display: none !important;
    }
}
@media (min-width: $breakpoint-xl + 1) {
    .ion-hide-xl-up {
        display: none !important;
    }
}
@media (max-width: $breakpoint-lg) {
    .ion-hide-lg-down {
        display: none !important;
    }
}
@media (min-width: $breakpoint-lg + 1) {
    .ion-hide-lg-up {
        display: none !important;
    }
}
@media (max-width: $breakpoint-md) {
    .ion-hide-md-down {
        display: none !important;
    }
}
@media (min-width: $breakpoint-md + 1) {
    .ion-hide-md-up {
        display: none !important;
    }
}
@media (max-width: $breakpoint-sm) {
    .ion-hide-sm-down {
        display: none !important;
    }
}
@media (min-width: $breakpoint-sm + 1) {
    .ion-hide-sm-up {
        display: none !important;
    }
}

.no-padding {
    padding: 0 !important;
}

[no-margin] {
    --margin-start: 0 !important;
    --margin-end: 0 !important;
    --margin-top: 0 !important;
    --margin-bottom: 0 !important;
    margin: 0 !important;
}
// cordova app is hidding tab-bar with buttons when keyboard is opened.. make him visible as is in PWA

app-tabs {
    ion-tab-bar.ios {
        &.tab-bar-hidden {
            display: block !important;
        }
    }
}

.keyboard {
    .conversations-list {
        @media (max-width: $breakpoint-xl) {
            max-height: calc(var(--app-height, 100vh) - 140px - var(--header-height, 0)) !important;
            height: calc(var(--app-height, 100vh) - 140px - var(--header-height, 0)) !important;
        }
        @media (max-width: $breakpoint-sm) {
            height: calc(var(--app-height, 100vh) - 130px - var(--header-height, 0)) !important;
            max-height: calc(var(--app-height, 100vh) - 130px - var(--header-height, 0)) !important;
        }
    }

    .chat-box-list {
        @media (max-width: $breakpoint-xl) and (min-width: $breakpoint-sm) {
            max-height: calc(var(--app-height, 100vh) - 240px - var(--header-height, 0)) !important;
            height: calc(var(--app-height, 100vh) - 240px - var(--header-height, 0)) !important;
        }
    }

    .scrollable-presentations {
        @media (max-width: $breakpoint-xl) and (min-width: $breakpoint-sm) {
            min-height: calc(var(--app-height, 100vh) - 136px - var(--header-height, 0)) !important;
            max-height: calc(var(--app-height, 100vh) - 136px - var(--header-height, 0)) !important;
        }
        @media (max-width: $breakpoint-sm) {
            &.with-header {
                min-height: calc(var(--app-height, 100vh) - 186px - var(--header-height, 0)) !important;
                max-height: calc(var(--app-height, 100vh) - 186px - var(--header-height, 0)) !important;
            }
        }
    }
}

.plt-android app-login .qr {
    top: 43px !important;
}

.plt-android app-registration .qr {
    top: 31px !important;
}

.plt-android.plt-cordova app-login .qr {
    top: 45px !important;
}

.plt-android.plt-cordova app-registration .qr {
    top: 33px !important;
}

.attendee-box-empty {
    width: 196px;
    height: 1px;
    &.small {
        width: 166px;
    }
}
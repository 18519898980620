@import "variables.scss";

body,
html {
    font-size: $default-font-size;
}

h1 {
    font-size: 200%;
    line-height: 2;
    font-weight: bold;
}

.uppercase {
    text-transform: uppercase !important;
}

.lowercase {
    text-transform: lowercase;
}

.opacity-80 {
    opacity: 0.8;
}

.pointer {
    cursor: pointer;
}

*:focus {
    outline: none;
    outline-color: transparent;
    outline-style: none;
    outline-width: 0;
}

*.active,
*.visited,
*:hover {
    outline: 0;
}
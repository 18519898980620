@import "variables.scss";

body.backdrop-no-scroll {
    @media (max-width: $breakpoint-sm) {
        min-height: var(--app-height, 100vh);
        max-height: calc(var(--app-height, 100vh) + var(--ion-safe-area-bottom, 0px) + var(--ion-safe-area-top, 0px));
    }
}

ion-app {
    &.translation-mode {
        flex-direction: row;
        justify-content: flex-end;

        ion-split-pane {
            max-width: calc(100vw - var(--translation-panel-width, 320px));
        }
    }
}

ion-router-outlet {
    ion-toolbar {
        // header part in main content
        --background: transparent; //var(--ion-color-primary);
        box-shadow: none;
    }

    ion-header {
        ion-button {
            min-width: 0;
        }

        ion-title {
            line-height: 56px;
            height: 56px;

            @media (max-width: $breakpoint-sm) {
                --padding-top: 0;
                --padding-bottom: 0;
            }
        }

        @media (max-width: $breakpoint-xl) {
            background: var(--ion-background-contrast-color);

            ion-toolbar {
                --padding-top: 0;
                --padding-bottom: #{$global-padding*0.75};
            }
        }

        @media (max-width: $breakpoint-xl) {
            border-bottom: 1px solid var(--ion-color-light);
        }
    }

    ion-menu-button {
        --color: var(--ion-color-dark);
    }

    ion-content {
        --overflow: scroll;
    }

    &.not-logged {
        a {
            color: var(--ion-color-primary);
        }
    }
}

ion-split-pane {
    &.disabled {
        opacity: 0;
    }

    @media (max-width: $breakpoint-xl) {
        //max-height: var(--app-height, 100vh);
    }
}

ion-header {
    --ion-text-color: var(--ion-color-dark);
    background: var(--ion-background-color);

    ion-toolbar {
        height: 56px;
        line-height: 32px;

        ion-title {
            &.full {
                padding-inline-start: 48px !important;
                padding-inline-end: 48px !important;
            }
        }
    }

    @media (min-width: $breakpoint-xl) {
        // because of scroll element in ion content...
        padding-right: 12px;
    }
}

.back-link {
    display: inline-block;

    @media (max-width: $breakpoint-sm) {
        width: 48px;
        height: 56px;
        padding: 0;
        position: relative;
    }

    svg-icon {
        margin: auto;
        transform: rotate(90deg);
        display: inline-block;

        svg {
            width: 16px;
            height: 16px;
        }

        @media (max-width: $breakpoint-sm) {
            margin: 12px 0 0 12px;
        }
    }
}

.change-event {
    margin: 2px 0 -8px 8px;
    // we have disabled left menu...
    display: block;
    opacity: 0;

    svg-icon {
        svg {
            width: 28px;
            height: 28px;
        }
    }

    ion-badge {
        position: absolute;
        margin-left: -12px;
        margin-top: -6px;
        border-radius: $global-radius;
        padding: 4px 7px;
    }
}

ion-content {
    --ion-text-color: var(--ion-color-dark);
    background: var(--ion-background-color);

    .langSwitch {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-bottom: $global-padding;

        span {
            cursor: pointer;
            margin: 0 $global-padding*0.5;
            opacity: 0.5;

            &.active {
                opacity: 1;
                font-weight: bold;
            }
        }
    }

    .footer-links {
        width: 100vw;
        text-align: center;
        background: var(--ion-color-medium);
        margin: $global-padding -#{$global-padding} -#{$global-padding};
        padding: $global-padding;

        a {
            padding: 0 $global-padding;
            color: var(--ion-color-contrast);
        }

        @media (min-width: 1366px) {
            display: none;
        }
    }

    .empty-data {
        text-align: center;
        padding-bottom: $global-padding*1.5;
        padding-top: $global-padding;
        width: 100%;

        svg {
            width: 64px;
            height: 64px;

            @media (max-width: $breakpoint-sm) {
                width: 32px;
                height: 32px;
            }
        }

        h3 {
            font-weight: bold;
            font-size: 100%;
            line-height: 19px;

            @media (max-width: $breakpoint-sm) {
                font-size: 88%;
            }
        }

        p {
            line-height: 19px;
            color: var(--ion-color-light);

            a {
                color: var(--ion-color-secondary);
                text-decoration: none;
            }
        }

        @media (max-width: $breakpoint-sm) {
            padding: $global-padding;
        }
    }
}

.content-footer,
ion-footer {
    .action-buttons {
        border-top: 1px solid var(--ion-color-light);
        background: var(--ion-background-contrast-color);

        &.two {
            ion-button {
                max-width: calc(100% - #{$global-padding});
                min-width: calc(100% - #{$global-padding});
                margin: $global-padding*0.5 !important;
                white-space: normal;
            }
        }

        &.one {
            ion-button {
                max-width: calc(100% - #{$global-padding});
                min-width: calc(100% - #{$global-padding});
                margin: $global-padding*0.5 !important;
                white-space: normal;
            }
        }
    }
}

// custom styles for our app
.flex {
    display: flex;
}

// center item vertically in page
.content-centered-column {
    // TODO[jg]  - var(--header-height, 0) was not working...
    min-height: calc(var(--app-height, 100vh) - #{$global-padding*2});
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: $breakpoint-xl) {
        min-height: calc(var(--app-height, 100vh) - #{$global-padding*2} - 156px);
    }
}

// center item horizontaly in page
.content-centered-row {
    min-height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

// main content box with border/shadow, including header/footer
.content-box {
    width: $content-width;
    max-width: calc(100vw - #{$left-panel-width + $global-padding*2});
    margin: 6vh auto;
    padding-top: calc(var(--header-height, 0)/2 * -1);

    h1 {
        margin: 0;
    }

    >ion-row {
        ion-button {
            margin: 0 !important;
        }
    }

    .content-box-inner {
        background-color: var(--ion-background-contrast-color);
        border-radius: 0 0 $global-radius $global-radius;
        box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.2);
        min-height: 72vh;

        .content-header {
            background: var(--ion-background-contrast-color);
            width: 100%;
            padding: $global-padding*1.5;
        }

        .content-data {
            width: 100%;
            padding: $global-padding*1.5;

            h2 {
                font-size: 130%;
                margin-top: $global-padding*0.75;
                margin-bottom: $global-padding*2;
            }

            hr {
                display: block;
                height: 1px;
                border: 0;
                border-top: 1px solid #{darken($bg-content, 10%)};
                margin: $global-padding $global-padding*1.5;
                padding: 0;

                &.full {
                    margin: $global-padding 0;
                }
            }

            .panel-info {
                padding: 6px $global-padding*1.5;
                border-bottom: 1px solid var(--ion-color-light);
                background: var(--ion-background-color);
            }
        }

        .content-footer {
            background: var(--ion-background-contrast-color);
            border-top: 1px solid #{darken($bg-content, 10%)};
            width: 100%;
            padding: $global-padding*1.5;

            ion-item {
                &.global {
                    width: 360px;
                    max-width: 95%;
                    padding-top: 2px;
                    float: right;

                    @media (max-width: 800px) {
                        float: left;
                        margin-top: $global-padding;
                        margin-right: 0;
                        width: 100vw;
                        max-width: 98%;
                        text-align: center;
                        margin-bottom: $global-padding;

                        ion-label {
                            text-align: left;
                            white-space: normal;
                            padding-right: $global-padding;
                        }
                    }
                }
            }

            ion-button {
                margin: 0;
            }
        }

        &.gray-bg {
            background: var(--ion-background-color);
        }

        @media (max-width: $breakpoint-xl) {
            min-height: calc(var(--app-height, 100vh) - 114px - var(--header-height, 0));
        }

        @media (min-height: 1200px) {
            min-height: 50vh;
        }
    }

    &.black-header {
        h1 {
            font-weight: normal;

            @media (max-width: $breakpoint-sm) {
                display: none;
            }
        }

        .top-header-link {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: $global-padding;

            svg-icon {
                margin-right: $global-padding*0.5;
                height: 24px;
            }
        }

        .content-box-inner {
            border-radius: 0 0 $global-radius $global-radius;
            background-color: var(--ion-color-dark-contrast);
            box-shadow: 1px 0 6px 0 rgba(0, 0, 0, 0.2);

            .content-header {
                padding: $global-padding $global-padding $global-padding $global-padding*1.5;
                background: var(--ion-color-dark);
                border-radius: $global-radius $global-radius 0 0;
                height: 72px;
                color: var(--ion-color-dark-contrast);

                ion-label {
                    color: var(--ion-color-dark-contrast);
                    padding-left: $global-padding;
                }

                svg-icon {
                    position: relative;
                    top: 6px;
                }

                .search-header {
                    ion-searchbar {
                        height: 42px;
                        padding: 2px;
                    }
                }

                @media (max-width: $breakpoint-xl) {
                    border-radius: 0;
                    display: none;
                }
            }

            @media (max-width: $breakpoint-xl) {
                border-radius: 0;
            }
        }
    }

    @media (max-width: $breakpoint-xl) {
        max-width: calc(100vw - #{$global-padding*2});
        margin: 0 auto;

        >h1 {
            display: none;
        }

        .content-box-inner {
            background: transparent;
            box-shadow: none;

            .content-header {
                background: transparent;
                padding: 0;
            }

            .content-data {
                @media (max-width: $breakpoint-sm) {
                    padding: 0;

                    h2 {
                        margin-bottom: 12px;
                        margin-top: $global-padding*2;

                        @media (max-width: $breakpoint-sm) {
                            margin-top: $global-padding*0.5;
                        }
                    }
                }
            }

            .content-footer {
                margin-top: $global-padding*2;
                background: transparent;

                @media (max-width: $breakpoint-sm) {
                    padding: $global-padding*2 0;
                }
            }
        }
    }

    &.wizard {
        margin: 0;
        width: var(--width);
        max-width: var(--width);
        min-width: var(--width);
        padding-top: 0;

        .content-box-inner {
            width: 100%;
            box-shadow: none;

            .content-data {
                //@media (min-height: 720px) and (min-width: $breakpoint-sm) {
                // minus modal margin, header and footer height
                max-height: calc(var(--app-height, 100vh) - 56px - 6px - 127px);
                min-height: 200px;
                height: calc(var(--app-height, 100vh) - 56px - 64px - 127px);
                overflow-y: scroll;
                -webkit-overflow-scrolling: touch;

                //}
                @media (max-width: $breakpoint-sm) {
                    max-height: calc(var(--app-height, 100vh) - 162px - 8px);
                    height: calc(var(--app-height, 100vh) - 162px - 8px);
                }

                @media (min-height: 1080px) {
                    height: 684px !important;
                }

                @media (min-height: 1080px) and (min-width: 1300px) {
                    height: 716px !important;
                }
            }

            .content-footer {
                @media (max-width: $breakpoint-lg) {
                    margin-top: 0;
                }
            }
        }
    }

    @media (max-width: $breakpoint-xl) {
        min-width: 100vw;
    }

    @media (max-width: $breakpoint-sm-real) {
        padding-top: $global-padding;

        .content-box-inner {
            .content-footer {
                ion-button {
                    width: 100%;
                }
            }
        }
    }
}

ion-col.col-30p {
    flex: 0 0 30%;
    width: 30%;
    max-width: 30%;
}

ion-col.col-25p {
    flex: 0 0 25%;
    width: 25%;
    max-width: 25%;
}

ion-col.col-20p {
    flex: 0 0 20%;
    width: 20%;
    max-width: 20%;
}

ion-col.col-15p {
    flex: 0 0 15%;
    width: 15%;
    max-width: 15%;
}

ion-col.col-10p {
    flex: 0 0 10%;
    width: 10%;
    max-width: 10%;
}

ion-col.col-5p {
    flex: 0 0 5%;
    width: 5%;
    max-width: 5%;
}

.force-update-page {
    width: 100vw;
    height: var(--app-height, 100vh);
    padding: $global-padding;
    background-color: #{$bg-content-logged-out};
    color: #{$color-content-logged-out};

    .inner {

        text-align: center;

        p {
            font-size: 120%;
        }

        ion-button {
            margin-top: $global-padding*4;
            width: 400px;
            max-width: calc(100vw - #{$global-padding*2});
        }
    }
}

.small-padding-right {
    padding-right: $global-padding*0.5;
}

.small-padding-left {
    padding-left: $global-padding*0.5;
}

.ios-pwa-install {
    position: fixed;
    bottom: 24px;
    width: 96vw;
    left: 50%;
    margin-left: -48vw;
    padding: 16px 16px 16px 60px;
    background: #f7f7f7 url("#{$assets-path}icons/ios/add.png") no-repeat 16px center;
    background-size: 32px auto;
    border-radius: 12px;
    font-size: 14px;

    img {
        width: 14px;
        margin: 0 6px -3px;
    }

    p {
        margin: 0;
        line-height: 1.5;
    }

    .arrow {
        position: absolute;
        left: 50%;
        margin-left: -10px;
        margin-top: 6px;
        width: 20px;
        height: 20px;
        background: #f7f7f7;
        transform: rotate(45deg);
    }

    @media (min-width: 768px) {
        width: 400px;
        top: 24px;
        right: 24px;
        bottom: auto;
        left: auto;

        .arrow {
            top: -10px;
            margin: 0;
            left: 68.5%;
        }
    }

    @media (min-width: 768px) and (max-height: 600px) {
        .arrow {
            left: 64.5%;
        }
    }
}
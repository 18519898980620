// colors
$primary-color: #2d2b65;
$primary-color-rgb: 45, 43, 101;
$primary-color-contrast: #ffffff;
$primary-color-contrast-rgb: 255, 255, 255;

$primary-light: #ff905f;

$secondary-color-congreet: #00355D;

$secondary-color: #58B1D7;
$secondary-color-rgb: 88, 177, 215;
$secondary-color-contrast: #ffffff;
$secondary-color-contrast-rgb: 255, 255, 255;

$warning-color: #ffce00;
$warning-color-rgb: 255, 206, 0;
$warning-color-contrast: #ffffff;
$warning-color-contrast-rgb: 255, 255, 255;

$danger-color: #f04141;
$danger-color-rgb: 245, 61, 61;
$danger-color-contrast: #ffffff;
$danger-color-contrast-rgb: 255, 255, 255;

$dark-color: #404040;
$dark-color-rgb: 64, 64, 64;
$dark-color-contrast: #ffffff;
$dark-color-contrast-rgb: 255, 255, 255;

$medium-color: #808080;
$medium-color-rgb: 128, 128, 128;
$medium-color-contrast: #ffffff;
$medium-color-contrast-rgb: 255, 255, 255;

$light-color: #CCCCCC;
$light-color-rgb: 204, 204, 204;
$light-color-contrast: #000000;
$light-color-contrast-rgb: 0, 0, 0;

// fonts
$default-font: "Poppins", "Roboto", sans-serif;
$default-font-size: 16px;

// left menu
$default-logo-file: 'images/logo-dashboard.svg';
$bg-menu-color: transparent;// #525E6A;
$bg-menu-image: 'bg-menu.png';
$menu-color: $light-color; //#D3D6D9;
$menu-color-highlight: lighten($menu-color, 25%);
$color-memu-highlight: #58B1D7;
$bg-overlay: #2f5597; //#525E6A;
$bg-overlay-rgb: 47, 85, 151;

// dashboard
$bg-dashboard-color: #525E6A;
$bg-dashboard-image: 'bg-dashboard.png';

// main content
$bg-content: #F8F8F8;
$bg-content-contrast: #ffffff;
$color-content: #000000;
$bg-content-logged-out: #ffffff;
$color-content-logged-out: $primary-color;

// global borders
$global-radius: 4px;
$global-padding: 16px;
$global-margin: 16px;

// inputs
$input-background: #ffffff;
$input-color: $dark-color;
$input-placeholder-color: #999999;
$input-border-color: #E7E7E7;//$light-color;
$input-height: 48px;

$form-width: 448px;

$content-width: 1320px;

// buttons
$button-height: 48px;

:root ion-app {
    --theme-menu-opacity: transparent;//linear-gradient(0deg, rgba(#{$bg-overlay-rgb},1) 0%, rgba(#{$bg-overlay-rgb},0.6) 20%, rgba(#{$bg-overlay-rgb},0.6) 85%, rgba(#{$bg-overlay-rgb},0.6) 100%);
    --ion-logo-menu: url('/assets/images/theme/full-logo-light.svg') !important;
    --ion-color-bottom-menu: #{$primary-color};
    --ion-color-keyword-box: #00355D;
    --ion-color-menu-footer: #{$primary-color} !important;
    --ion-menu-overlay: transparent !important;
    --ion-menu-header-overlay: transparent !important;
    --ion-header-overlay: transparent !important;
}

ion-menu ion-content p.welcome-text {
    padding: 0 24px !important;
    font-size: 165%;
    strong {
        font-size: 109%;
    }
}

ion-menu ion-header ion-toolbar ion-title.title-default {
    background-position: 24px 26px !important;
}

ion-menu .footer-links {
    display: none !important;
}

.dashboard .content-header .logo {
    background-color: transparent !important;
}
